import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { logo } from '../shared/constants/base64';
import { ExcelUtils } from '../utils/excel-utils';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor(private _excelUtils: ExcelUtils) {}
  generalLedgersExcel(
    generalLedgers: any[],
    fileName: string,
    startDate: string,
    endDate: string
  ): void {
    const mapped = generalLedgers.map((general) => {
      return {
        description: general.name,
        code: general.code,
        debit: general.debit,
        credit: general.credit,
        balance: general.balance,
      };
    });
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('generalLedgers sheet');
    // logo
    const logoImage = workbook.addImage({
      extension: 'png',
      base64: logo,
    });
    worksheet.addImage(logoImage, {
      tl: { col: 1, row: 1 },
      ext: { width: 70, height: 70 },
    });
    // header
    worksheet.mergeCells('C1:D2');
    worksheet.getCell('C1').font = {
      name: 'Khmer OS Muol Light',
      size: 14,
      color: { argb: '000000' },
    };
    worksheet.getCell('C1').value = 'ព្រះរាជាណាចក្រកម្ពុជា';
    worksheet.getCell('C1').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.mergeCells('C3:D4');
    worksheet.getCell('C3').value = 'ជាតិ សាសនា ព្រះមហាក្សត្រ';
    worksheet.getCell('C3').font = {
      name: 'Khmer OS Muol Light',

      size: 14,
      color: { argb: '000000' },
    };
    worksheet.getCell('C3').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    // scale row
    worksheet.getColumn('A').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getColumn('B').width = 45;
    worksheet.getColumn('C').width = 20;
    worksheet.getColumn('C').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getColumn('D').width = 20;
    worksheet.getColumn('E').width = 20;
    worksheet.getColumn('F').width = 20;
    // បេឡាជាតិសន្តិសុខសង្គម
    worksheet.mergeCells('A6:B7');
    worksheet.getCell('A6').font = {
      name: 'Khmer OS Muol Light',
      bold: false,
      size: 12,
      color: { argb: '000000' },
    };
    worksheet.getCell('A6').value = 'បេឡាជាតិសន្តិសុខសង្គម';
    worksheet.getCell('A6').alignment = {
      vertical: 'middle',
      horizontal: 'left',
      indent: 1,
      wrapText: true,
    };
    //ន.គ្រប់គ្រងការវិនិយោគមូលនិធិសន្តិសុខសង្គម
    worksheet.mergeCells('A8:B10');
    worksheet.getCell('A8').font = {
      name: 'Khmer OS System',
      bold: false,
      size: 12,
      color: { argb: '000000' },
    };
    worksheet.getCell('A8').value =
      'ន.គ្រប់គ្រងការវិនិយោគមូលនិធិសន្តិសុខសង្គម\nលេខៈ ..................គ.វ.ស/ប.ទ';
    worksheet.getCell('A8').alignment = {
      vertical: 'middle',
      horizontal: 'left',
      indent: 1,
      wrapText: true,
    };
    //សៀវភៅធំ
    worksheet.mergeCells('C6:D8');
    worksheet.getCell('C6').font = {
      name: 'Khmer OS Muol Light',

      size: 14,
      color: { argb: '000000' },
    };
    worksheet.getCell('C6').value = 'សៀវភៅធំ';
    worksheet.getCell('C6').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    //ថ្ងៃទី
    worksheet.mergeCells('C9:D10');
    worksheet.getCell('C9').font = {
      name: 'Khmer OS Battambang',

      size: 12,
      color: { argb: '000000' },
    };
    worksheet.getCell('C9').value = 'ថ្ងៃទី៖ ' + startDate + '-' + endDate;
    worksheet.getCell('C9').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    //រូបិយវត្ថុ
    worksheet.getCell('F11').font = {
      name: 'Khmer OS System',
      size: 12,
      color: { argb: '000000' },
    };
    worksheet.getCell('F11').value = 'រូបិយវត្ថុ: រៀល';
    worksheet.getCell('F11').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    //header row
    const row = worksheet.addRow([
      'ល.រ',
      'បរិយាយ',
      'លេខកូដ',
      'debit',
      'credit',
      'balance',
    ]);
    row.font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };
    row.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F4AF85' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    generalLedgers.forEach((item, index) => {
      const col = worksheet.addRow([
        index + 1,
        item.name || '',
        item.code,
        item.debit,
        item.credit,
        item.balance,
      ]);
      col.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });
    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }

  growthRateExcel(
    growthRate: any[],
    fileName: string,
    firstYear: string,
    secondYear: string,
    firstYearTotalAsset: number,
    firstYearCustomerDeposit: number,
    firstYearLiabilityExOwnToNBC: number,
    firstYearEquity: number,
    firstYearConcentrationRiskBudgetLimitInRiel: number,
    secondYearTotalAssetSecondYear: number,
    secondYearConRisk: number,
    growthRiskTotalAsset: number
  ): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('growthRates sheet');
    // logo
    const logoImage = workbook.addImage({
      extension: 'png',
      base64: logo,
    });
    worksheet.addImage(logoImage, {
      tl: { col: 1, row: 1 },
      ext: { width: 70, height: 70 },
    });
    // header
    worksheet.mergeCells('D1:E2');
    worksheet.getCell('D1').font = {
      name: 'Khmer OS Muol Light',
      size: 14,
      color: { argb: '000000' },
    };
    worksheet.getCell('D1').value = 'ព្រះរាជាណាចក្រកម្ពុជា';
    worksheet.getCell('D1').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    worksheet.mergeCells('D3:E5');
    worksheet.getCell('D3').value = 'ជាតិ សាសនា ព្រះមហាក្សត្រ';
    worksheet.getCell('D3').font = {
      name: 'Khmer OS Muol Light',
      size: 14,
      color: { argb: '000000' },
    };
    worksheet.getCell('D3').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    // scale row
    worksheet.getColumn('A').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getColumn('B').width = 20;
    worksheet.getColumn('C').width = 45;
    worksheet.getColumn('C').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getColumn('D').width = 20;
    worksheet.getColumn('E').width = 20;
    worksheet.getColumn('F').width = 20;
    worksheet.getColumn('G').width = 20;
    worksheet.getColumn('H').width = 20;
    worksheet.getColumn('I').width = 20;
    worksheet.getColumn('J').width = 20;
    worksheet.getColumn('K').width = 20;
    worksheet.getColumn('L').width = 20;

    // បេឡាជាតិសន្តិសុខសង្គម
    worksheet.mergeCells('A6:C7');
    worksheet.getCell('A6').font = {
      name: 'Khmer OS Muol Light',
      bold: false,
      size: 12,
      color: { argb: '000000' },
    };
    worksheet.getCell('A6').value = 'បេឡាជាតិសន្តិសុខសង្គម';
    worksheet.getCell('A6').alignment = {
      vertical: 'middle',
      horizontal: 'left',
      indent: 1,
      wrapText: true,
    };
    //ន.គ្រប់គ្រងការវិនិយោគមូលនិធិសន្តិសុខសង្គម
    worksheet.mergeCells('A8:B10');
    worksheet.getCell('A8').font = {
      name: 'Khmer OS System',
      bold: false,
      size: 12,
      color: { argb: '000000' },
    };
    worksheet.getCell('A8').value =
      'ន.គ្រប់គ្រងការវិនិយោគមូលនិធិសន្តិសុខសង្គម\nលេខៈ ..................គ.វ.ស/ប.ទ';
    worksheet.getCell('A8').alignment = {
      vertical: 'middle',
      horizontal: 'left',
      indent: 1,
      wrapText: true,
    };
    //សៀវភៅធំ
    worksheet.mergeCells('D6:E8');
    worksheet.getCell('D6').font = {
      name: 'Khmer OS Muol Light',
      size: 14,
      color: { argb: '000000' },
    };
    worksheet.getCell('D6').value = 'ប្រៀបធៀបអត្រាកំណើន';
    worksheet.getCell('D6').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    //year
    worksheet.mergeCells('D9:E10');
    worksheet.getCell('D9').font = {
      name: 'Khmer OS Battambang',
      size: 12,
      color: { argb: '000000' },
    };
    worksheet.getCell('D9').value = 'ឆ្នាំ៖ ' + firstYear + '-' + secondYear;
    worksheet.getCell('D9').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };

    worksheet.mergeCells('A12:A11');
    worksheet.getCell('A12').value = 'ល.រ';
    worksheet.getCell('A12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('A12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '028088' },
    };
    worksheet.getCell('A12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('A12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.mergeCells('B12:B11');
    // worksheet.getCell('B12').value = 'N(NBC)';
    worksheet.getCell('B12').value = 'N⁰ (NBC)';
    worksheet.getCell('B12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('B12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '028088' },
    };
    worksheet.getCell('B12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('B12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.mergeCells('C12:C11');
    worksheet.getCell('C12').value = 'បរិយាយ';
    worksheet.getCell('C12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('C12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '028088' },
    };
    worksheet.getCell('C12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.mergeCells('D11:H11');
    worksheet.getCell('D11').value = firstYear;
    worksheet.getCell('D11').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('D11').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0063a9' },
    };
    worksheet.getCell('D11').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('D12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.mergeCells('I11:J11');
    worksheet.getCell('I11').value = secondYear;
    worksheet.getCell('I11').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('I11').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0063a9' },
    };
    worksheet.getCell('I11').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('I12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.mergeCells('K11:L11');
    worksheet.getCell('K11').value = 'អត្រាកំណើន 5%';
    worksheet.getCell('K11').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('K11').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e39100' },
    };
    worksheet.getCell('K11').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('K11').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.getCell('D12').value = 'ទ្រព្យសរុប';
    worksheet.getCell('D12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('D12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0063a9' },
    };
    worksheet.getCell('D12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('D12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.getCell('E12').value = 'អតិថិជនដាក់ប្រាក់សរុប';
    worksheet.getCell('E12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('E12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0063a9' },
    };
    worksheet.getCell('E12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('E12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.getCell('F12').value = 'បំណុលធ្លាប់ជំពាក់ NBC';
    worksheet.getCell('F12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('F12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0063a9' },
    };
    worksheet.getCell('F12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('F12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.getCell('G12').value = 'សមធម៌សរុប';
    worksheet.getCell('G12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('G12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0063a9' },
    };
    worksheet.getCell('G12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('G12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.getCell('H12').value = 'ដែនកំណត់ថវិកា';
    worksheet.getCell('H12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('H12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0063a9' },
    };
    worksheet.getCell('H12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('H12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.getCell('I12').value = 'ទ្រព្យសរុប';
    worksheet.getCell('I12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('I12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '5e0fc6' },
    };
    worksheet.getCell('I12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('I12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.getCell('J12').value = 'ហានិភ័យសរុប';
    worksheet.getCell('J12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('J12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '5e0fc6' },
    };
    worksheet.getCell('J12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('J12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.getCell('K12').value = 'ទ្រព្យសរុប';
    worksheet.getCell('K12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('K12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e39100' },
    };
    worksheet.getCell('K12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('K12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    worksheet.getCell('L12').value = 'ហានិភ័យសរុប';
    worksheet.getCell('L12').alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getCell('L12').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'e39100' },
    };
    worksheet.getCell('L12').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('L12').font = {
      name: 'Khmer OS Battambang',
      size: 10,
      color: { argb: '000000' },
    };

    // const row = worksheet.addRow([
    //   'ល.រ',
    //   'N(NBC)',
    //   'បរិយាយ',
    //   'assets',
    //   'Customer Deposits',
    //   'Liabilities ex-owed NBC',
    //   'Equity',
    //   'Con. Budget Limit',
    //   'Total Assets',
    //   'Con. Risk',
    //   'Total Assets',
    //   'Con. Risk',
    // ]);

    // row.font = {
    //   name: 'Khmer OS Battambang',
    //   size: 10,
    //   color: { argb: '000000' },
    // };
    // row.alignment = {
    //   vertical: 'middle',
    //   horizontal: 'center',
    //   wrapText: true,
    // };
    // row.eachCell((cell) => {
    //   cell.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: 'F4AF85' },
    //   };
    //   cell.border = {
    //     top: { style: 'thin' },
    //     left: { style: 'thin' },
    //     bottom: { style: 'thin' },
    //     right: { style: 'thin' },
    //   };
    // });

    growthRate.forEach((item, index) => {
      const col = worksheet.addRow([
        index + 1,
        item.firstYear.nbcNumber,
        item.name || '',
        item.firstYear.asset,
        item.firstYear.customerDeposit,
        item.firstYear.liabilityExOwnToNBC,
        item.firstYear.equity,
        item.firstYear.concentrationRiskBudgetLimitInRiel,
        item.secondYear.asset,
        item.growthRate.conRisk,
        item.growthRate.totalAsset,
        item.growthRate.conRisk,
      ]);
      col.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    const resultColumn = worksheet.addRow([
      '',
      '',
      '',
      firstYearTotalAsset,
      firstYearCustomerDeposit,
      firstYearLiabilityExOwnToNBC,
      firstYearEquity,
      firstYearConcentrationRiskBudgetLimitInRiel,
      secondYearTotalAssetSecondYear,
      secondYearConRisk,
      growthRiskTotalAsset,
      '',
    ]);
    resultColumn.font = {
      name: 'Khmer OS Battambang',
      bold: true,
      size: 9,
    };

    resultColumn.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }

  generateFundAccountReport() {
    try {
      const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('របាយការណ៍គ្រប់គ្រងមូលនិធិ');
      worksheet.pageSetup = {
        orientation: 'portrait',
        paperSize: 9,
      };
      worksheet.getColumn('A').width = 40;
      worksheet.getColumn('B').width = 8;
      worksheet.getColumn('C').width = 25;
      worksheet.getColumn('D').width = 25;
      worksheet.getColumn('E').width = 25;
      ExcelUtils.addNationalMotto(worksheet, 'A', 1);
      // logo
      ExcelUtils.addLogo(workbook, worksheet);
      ExcelUtils.addNSSFInfo(worksheet, 'A', 4);
      const titleCell = worksheet.getCell(`A${worksheet.rowCount + 3}`);
      titleCell.value = 'របាយការណ៍គ្រប់គ្រងមូលនិធិ (Fund Account)';
      titleCell.style = {
        font: {
          name: 'Khmer OS Muol Light',
          size: 12,
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '8EA9DB' },
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
        border: {
          bottom: {
            style: 'medium',
          },
          top: {
            style: 'medium',
          },
          left: {
            style: 'medium',
          },
          right: {
            style: 'medium',
          },
        },
      };
      worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
      const subTitleRows = worksheet.getRows(worksheet.rowCount + 1, 2);
      subTitleRows.forEach((row, rowIdx) => {
        if (rowIdx === 0) {
          row.values = ['', 'កូដ', 'សមតុល្យសរុប', 'សមតុល្យសរុប', 'សមតុល្យសរុប'];
        } else {
          row.values = ['', '', '២០២៤', '២០២៣', '២០២២'];
        }
        for (let cellIdx = 1; cellIdx <= 5; cellIdx++) {
          const cell = row.getCell(cellIdx);
          cell.style = {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D9E1F2' },
            },
            font: {
              name: 'Khmer OS Battambang',
              size: 12,
            },
            alignment: {
              horizontal: 'center',
              vertical: 'middle',
            },
          };

          switch (rowIdx) {
            case 0:
              if (cellIdx === 1) {
                cell.border = {
                  left: {
                    style: 'medium',
                  },
                  top: {
                    style: 'medium',
                  },
                };
              } else if (cellIdx === 5) {
                cell.border = {
                  right: {
                    style: 'medium',
                  },
                  top: {
                    style: 'medium',
                  },
                };
              } else {
                cell.border = {
                  top: {
                    style: 'medium',
                  },
                };
              }
              break;
            case subTitleRows.length - 1:
              if (cellIdx === 1) {
                cell.border = {
                  left: {
                    style: 'medium',
                  },
                  bottom: {
                    style: 'medium',
                  },
                };
              } else if (cellIdx === 5) {
                cell.border = {
                  right: {
                    style: 'medium',
                  },
                  bottom: {
                    style: 'medium',
                  },
                };
              } else {
                cell.border = {
                  bottom: {
                    style: 'medium',
                  },
                };
              }
              break;
            default:
              break;
          }
        }
      });
      worksheet.mergeCells(`B${worksheet.rowCount - 1}:B${worksheet.rowCount}`);
      worksheet.getCell(`B${worksheet.rowCount - 1}`).border = {
        bottom: {
          style: 'medium',
        },
      };
      worksheet.addRow(['មូលនិធិទទួលបានតាមរបប', '', '-', '-', '-']);
      worksheet.addRow(['របបប្រាក់សោធន', '', '', '', '']);
      worksheet.addRow(['   វិស័យសាធារណៈ', '', '', '', '']);
      worksheet.addRow(['   វិស័យឯកជន', '', '', '', '']);
      worksheet.addRow(['របបហានិភ័យការងារ', '', '', '', '']);
      worksheet.addRow(['   វិស័យសាធារណៈ', '', '', '', '']);
      worksheet.addRow(['   វិស័យឯកជន', '', '', '', '']);
      worksheet.addRow(['របបថែទាំសុខភាព', '', '', '', '']);
      worksheet.addRow(['   វិស័យសាធារណៈ', '', '', '', '']);
      worksheet.addRow(['   វិស័យឯកជន', '', '', '', '']);
      worksheet.addRow(['មូលនិធិដើមគ្រា', '', 4000000, 4000000, 4000000]);
      worksheet.addRow(['មូលនិធិទទួលបានពីផលវិនិយោគ', '', '-', '-', '-']);
      worksheet.addRow(['មូលនិធិសរុប', '', 4000000, 4000000, 4000000]);
      const fundDataRows = worksheet.getRows(worksheet.rowCount - 12, 13);
      fundDataRows.forEach((row, rowIdx) => {
        row.eachCell((cell, colNumber) => {
          cell.font = {
            name: 'Khmer OS Battambang',
            size: 12,
          };
          if (colNumber !== 1) {
            cell.alignment = {
              horizontal: 'right',
              vertical: 'middle',
            };
          }
          if (
            (rowIdx === 0 && colNumber === 1) ||
            (rowIdx === fundDataRows.length - 1 && colNumber === 1)
          ) {
            cell.font.bold = true;
          }

          if (
            rowIdx === 2 ||
            rowIdx === 3 ||
            rowIdx === 5 ||
            rowIdx === 6 ||
            rowIdx === 8 ||
            rowIdx === 9
          ) {
            if (colNumber === 1) {
              cell.font.italic = true;
            }
          }

          if (rowIdx === fundDataRows.length - 1) {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
                bottom: {
                  style: 'medium',
                },
              };
            } else if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
                bottom: {
                  style: 'medium',
                },
              };
            } else {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
              };
            }
          } else {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
              };
            }

            if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
              };
            }
          }
        });
      });
      worksheet.addRow(['ចំណូលសរុបជាសាច់ប្រាក់', '', '-', '-', '']);
      worksheet.addRow(['ចំណូលការប្រាក់', '', '-', '-', '']);
      worksheet.addRow([
        'ចំណូលការប្រាក់បង្គរ',
        '',
        2109589041.06,
        2321917808.22,
        2321917808.22,
      ]);
      worksheet.addRow([
        'ផលពីការវិនិយោគប្រចាំឆ្នាំ (ROI)',
        '',
        '6.25%',
        '6.25%',
        '',
      ]);
      const incomeDataRows = worksheet.getRows(worksheet.rowCount - 3, 4);
      incomeDataRows.forEach((row, rowIdx) => {
        row.eachCell((cell, colNumber) => {
          cell.font = {
            name: 'Khmer OS Battambang',
            size: 12,
          };
          if (colNumber !== 1) {
            cell.alignment = {
              horizontal: 'right',
              vertical: 'middle',
            };
          }
          if (
            (rowIdx === 0 && colNumber === 1) ||
            (rowIdx === incomeDataRows.length - 1 && colNumber === 1)
          ) {
            cell.font.bold = true;
          }
          if (rowIdx !== incomeDataRows.length - 1) {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
              };
            }
            if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
              };
            }
          } else {
            if (colNumber === 1) {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
                left: {
                  style: 'medium',
                },
              };
            } else if (colNumber === 5) {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
                right: {
                  style: 'medium',
                },
              };
            } else {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
              };
            }
          }
        });
      });
      worksheet.addRow(['ចំណាយ', '', '-', '', '-']);
      worksheet.addRow(['ចំណាយប្រតិបត្តិការ', '', '-', '', '-']);
      worksheet.addRow(['រដ្ឋបាល', '', '', '', '']);
      worksheet.addRow(['ប្រតិបត្តិការវិនិយោគ', '', '', '', '']);
      worksheet.addRow(['ចំណាយមិនមែនប្រតិបត្តិការ', '', '', '', '']);
      worksheet.addRow(['ពន្ធ', '', '', '', '']);
      worksheet.addRow(['ផ្សេងៗ', '', '', '', '']);
      worksheet.addRow(['កំណើន/(ឱនភាព)សុទ្ធប្រចាំឆ្នាំ', '', '', '', '']);

      const expenseDataRows = worksheet.getRows(worksheet.rowCount - 7, 8);
      expenseDataRows.forEach((row, rowIdx) => {
        row.eachCell((cell, colNumber) => {
          cell.font = {
            name: 'Khmer OS Battambang',
            size: 12,
          };
          if (
            (rowIdx === 0 || rowIdx === 1 || rowIdx === 4) &&
            colNumber === 1
          ) {
            cell.font = {
              bold: true,
              name: 'Khmer OS Battambang',
              size: 12,
            };
          }
          if (colNumber === 1) {
            cell.alignment = {
              horizontal: 'left',
              vertical: 'middle',
            };
          } else {
            cell.alignment = {
              horizontal: 'right',
              vertical: 'middle',
            };
          }
          if (rowIdx !== expenseDataRows.length - 1) {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
              };
            }
            if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
              };
            }
          } else {
            if (colNumber === 1) {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
                left: {
                  style: 'medium',
                },
              };
            } else if (colNumber === 5) {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
                right: {
                  style: 'medium',
                },
              };
            } else {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
              };
            }
          }
        });
      });

      worksheet.addRow([
        'ទ្រព្យវិនិយោគសុទ្ធ(Net Asset) ដើមគ្រា',
        '',
        400000000,
        400000000,
        '',
      ]);
      worksheet.addRow([
        'ទ្រព្យវិនិយោគសុទ្ធ(Net Asset) ចុងគ្រា',
        '',
        400000000,
        400000000,
        400000000,
      ]);
      const assetDataRows = worksheet.getRows(worksheet.rowCount - 1, 2);
      assetDataRows.forEach((row, rowIdx) => {
        row.eachCell((cell, colNumber) => {
          cell.font = {
            name: 'Khmer OS Battambang',
            size: 12,
          };

          if (colNumber === 1) {
            cell.alignment = {
              horizontal: 'left',
              vertical: 'middle',
            };
          } else {
            cell.alignment = {
              horizontal: 'right',
              vertical: 'middle',
            };
          }
          if (rowIdx !== assetDataRows.length - 1) {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
              };
            }
            if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
              };
            }
          } else {
            if (colNumber === 1) {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
                left: {
                  style: 'medium',
                },
              };
            } else if (colNumber === 5) {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
                right: {
                  style: 'medium',
                },
              };
            } else {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
              };
            }
          }
        });
      });
      worksheet.addRow([]);
      ExcelUtils.addFooter(worksheet, 'A', 'C');

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `របាយការណ៍លំហូរសាច់ប្រាក់ - ${new Date().getTime()}.xlsx`);
      });
    } catch (error) {
      console.error(error);
    }
  }

  generateIncomeStatementReport() {
    try {
      const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('របាយការណ៍ចំណេញខាត');
      worksheet.pageSetup = {
        orientation: 'portrait',
        paperSize: 9,
      };
      worksheet.getColumn('A').width = 40;
      worksheet.getColumn('B').width = 8;
      worksheet.getColumn('C').width = 25;
      worksheet.getColumn('D').width = 25;
      worksheet.getColumn('E').width = 25;
      ExcelUtils.addNationalMotto(worksheet, 'A', 1);
      // logo
      ExcelUtils.addLogo(workbook, worksheet);
      ExcelUtils.addNSSFInfo(worksheet, 'A', 4);
      const titleCell = worksheet.getCell(`A${worksheet.rowCount + 3}`);
      titleCell.value = 'របាយការណ៍ចំណេញខាត (Income Statement)';
      titleCell.style = {
        font: {
          name: 'Khmer OS Muol Light',
          size: 12,
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '8EA9DB' },
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
        border: {
          bottom: {
            style: 'medium',
          },
          top: {
            style: 'medium',
          },
          left: {
            style: 'medium',
          },
          right: {
            style: 'medium',
          },
        },
      };
      worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
      const subTitleRows = worksheet.getRows(worksheet.rowCount + 1, 2);
      subTitleRows.forEach((row, rowIdx) => {
        if (rowIdx === 0) {
          row.values = ['', 'កូដ', 'សមតុល្យសរុប', 'សមតុល្យសរុប', 'សមតុល្យសរុប'];
        } else {
          row.values = ['', '', '២០២៤', '២០២៣', '២០២២'];
        }
        for (let cellIdx = 1; cellIdx <= 5; cellIdx++) {
          const cell = row.getCell(cellIdx);
          cell.style = {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D9E1F2' },
            },
            font: {
              name: 'Khmer OS Battambang',
              size: 12,
            },
            alignment: {
              horizontal: 'center',
              vertical: 'middle',
            },
          };

          switch (rowIdx) {
            case 0:
              if (cellIdx === 1) {
                cell.border = {
                  left: {
                    style: 'medium',
                  },
                  top: {
                    style: 'medium',
                  },
                };
              } else if (cellIdx === 5) {
                cell.border = {
                  right: {
                    style: 'medium',
                  },
                  top: {
                    style: 'medium',
                  },
                };
              } else {
                cell.border = {
                  top: {
                    style: 'medium',
                  },
                };
              }
              break;
            case subTitleRows.length - 1:
              if (cellIdx === 1) {
                cell.border = {
                  left: {
                    style: 'medium',
                  },
                  bottom: {
                    style: 'medium',
                  },
                };
              } else if (cellIdx === 5) {
                cell.border = {
                  right: {
                    style: 'medium',
                  },
                  bottom: {
                    style: 'medium',
                  },
                };
              } else {
                cell.border = {
                  bottom: {
                    style: 'medium',
                  },
                };
              }
              break;
            default:
              break;
          }
        }
      });
      worksheet.mergeCells(`B${worksheet.rowCount - 1}:B${worksheet.rowCount}`);
      worksheet.getCell(`B${worksheet.rowCount - 1}`).border = {
        bottom: {
          style: 'medium',
        },
      };

      worksheet.addRow(['ចំណូល', '', 250000000, 250000000, '']);
      worksheet.addRow([' ចំណូលការប្រាក់', '', 250000000, 250000000, '']);
      worksheet.addRow(['ចំណាយសរុប', '', '-', '-', '']);
      worksheet.addRow([' ចំណាយប្រតិបត្តិបត្តិការ', '', '-', '-', '']);
      worksheet.addRow([
        'ចំណេញ/(ខាត) មុនកាត់ពន្ធ',
        '',
        250000000,
        250000000,
        '',
      ]);
      worksheet.addRow(['ចំណាយពន្ធ', '', '-', '-', '']);
      worksheet.addRow([' ចំណាយពន្ធកាត់ទុក 6%', '', '-', '-', '']);
      worksheet.addRow([' ចំណាយពន្ធមូលធន 20%', '', '-', '-', '']);
      worksheet.addRow([
        'ចំណេញ/(ខាត) ក្រោយកាត់ពន្ធ',
        '',
        250000000,
        250000000,
        '',
      ]);

      const fundDataRows = worksheet.getRows(worksheet.rowCount - 8, 9);
      fundDataRows.forEach((row, rowIdx) => {
        row.eachCell((cell, colNumber) => {
          cell.font = {
            name: 'Khmer OS Battambang',
            size: 12,
          };
          if (colNumber !== 1) {
            cell.alignment = {
              horizontal: 'right',
              vertical: 'middle',
            };
          }
          if (
            rowIdx === 0 ||
            rowIdx === 2 ||
            rowIdx === 4 ||
            rowIdx === fundDataRows.length - 1
          ) {
            if (colNumber === 1) {
              cell.font.bold = true;
            }
          }

          if (rowIdx === fundDataRows.length - 1) {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
                bottom: {
                  style: 'medium',
                },
              };
            } else if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
                bottom: {
                  style: 'medium',
                },
              };
            } else {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
              };
            }
          } else {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
              };
            }

            if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
              };
            }
          }
        });
      });

      worksheet.addRow([]);
      ExcelUtils.addFooter(worksheet, 'A', 'C');
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `របាយការណ៍ចំណេញខាត - ${new Date().getTime()}.xlsx`);
      });
    } catch (error) {
      console.error(error);
    }
  }

  generateNetAssetStatementReport() {
    try {
      const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('របាយការណ៍មូលធន');
      worksheet.pageSetup = {
        orientation: 'portrait',
        paperSize: 9,
      };
      worksheet.getColumn('A').width = 55;
      worksheet.getColumn('B').width = 8;
      worksheet.getColumn('C').width = 25;
      worksheet.getColumn('D').width = 25;
      worksheet.getColumn('E').width = 25;

      // national motto
      ExcelUtils.addNationalMotto(worksheet, 'A', 1);
      // logo
      ExcelUtils.addLogo(workbook, worksheet);
      ExcelUtils.addNSSFInfo(worksheet, 'A', 4);
      const titleCell = worksheet.getCell(`A${worksheet.rowCount + 3}`);
      titleCell.value = 'របាយការណ៍ទ្រព្យវិនិយោគ (Statement of Net Asset)';
      titleCell.style = {
        font: {
          name: 'Khmer OS Muol Light',
          size: 12,
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '8EA9DB' },
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
        border: {
          bottom: {
            style: 'medium',
          },
          top: {
            style: 'medium',
          },
          left: {
            style: 'medium',
          },
          right: {
            style: 'medium',
          },
        },
      };
      worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
      const subTitleRows = worksheet.getRows(worksheet.rowCount + 1, 2);
      subTitleRows.forEach((row, rowIdx) => {
        if (rowIdx === 0) {
          row.values = ['', 'កូដ', 'សមតុល្យសរុប', 'សមតុល្យសរុប', 'សមតុល្យសរុប'];
        } else {
          row.values = ['', '', '២០២៤', '២០២៣', '២០២២'];
        }
        for (let cellIdx = 1; cellIdx <= 5; cellIdx++) {
          const cell = row.getCell(cellIdx);
          cell.style = {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D9E1F2' },
            },
            font: {
              name: 'Khmer OS Battambang',
              size: 12,
            },
            alignment: {
              horizontal: 'center',
              vertical: 'middle',
            },
          };

          switch (rowIdx) {
            case 0:
              if (cellIdx === 1) {
                cell.border = {
                  left: {
                    style: 'medium',
                  },
                  top: {
                    style: 'medium',
                  },
                };
              } else if (cellIdx === 5) {
                cell.border = {
                  right: {
                    style: 'medium',
                  },
                  top: {
                    style: 'medium',
                  },
                };
              } else {
                cell.border = {
                  top: {
                    style: 'medium',
                  },
                };
              }
              break;
            case subTitleRows.length - 1:
              if (cellIdx === 1) {
                cell.border = {
                  left: {
                    style: 'medium',
                  },
                  bottom: {
                    style: 'medium',
                  },
                };
              } else if (cellIdx === 5) {
                cell.border = {
                  right: {
                    style: 'medium',
                  },
                  bottom: {
                    style: 'medium',
                  },
                };
              } else {
                cell.border = {
                  bottom: {
                    style: 'medium',
                  },
                };
              }
              break;
            default:
              break;
          }
        }
      });

      worksheet.mergeCells(`B${worksheet.rowCount - 1}:B${worksheet.rowCount}`);
      worksheet.getCell(`B${worksheet.rowCount - 1}`).border = {
        bottom: {
          style: 'medium',
        },
      };

      worksheet.addRow([
        'ទ្រព្យវិនិយោគសុទ្ធសរុប (Total Net Asset)',
        '',
        '',
        '',
        '',
      ]);
      worksheet.addRow(['ទ្រព្យសកម្ម', '', '', '', '']);
      worksheet.addRow(['សាច់ប្រាក់ក្នុងធនាគារ', '', '', '', '']);
      worksheet.addRow(['បញ្ញើមានកាលកំណត់រយៈពេលខ្លី', '', '', '', '']);
      worksheet.addRow(['មូលបត្ររយៈពេលខ្លី', '', '', '', '']);
      worksheet.addRow(['មូលបត្រក្រៅប្រទេសរយៈពេលខ្លី', '', '', '', '']);
      worksheet.addRow(['ទ្រព្យអសកម្ម', '', '', '', '']);
      worksheet.addRow(['បញ្ញើមានកាលកំណត់រយៈពេលវែង', '', '', '', '']);
      worksheet.addRow([
        'សញ្ញាបណ្ណសាជីវកម្មមានបុគ្គលធានា(CIA) រយៈពេលវែង',
        '',
        '',
        '',
        '',
      ]);
      worksheet.addRow(['មូលបត្រក្រៅប្រទេសរយៈពេលវែង', '', '', '', '']);
      worksheet.addRow(['មូលប្របំណុលរដ្ឋ', '', '', '', '']);
      worksheet.addRow(['មូលប្រកម្មសិទ្ធិ', '', '', '', '']);
      worksheet.addRow(['អចលនទ្រព្យ', '', '', '', '']);
      worksheet.addRow(['គំរោងភាពជាដៃគូរដ្ឋ និងឯកជន', '', '', '', '']);

      const fundDataRows = worksheet.getRows(worksheet.rowCount - 13, 14);
      fundDataRows.forEach((row, rowIdx) => {
        row.eachCell((cell, colNumber) => {
          cell.font = {
            name: 'Khmer OS Battambang',
            size: 12,
          };
          if (colNumber !== 1) {
            cell.alignment = {
              horizontal: 'right',
              vertical: 'middle',
            };
          } else {
            cell.alignment = {
              horizontal: 'left',
              vertical: 'middle',
            };
          }
          if (rowIdx === 0 || rowIdx === 1 || rowIdx === 6) {
            if (colNumber === 1) {
              cell.font.bold = true;
            }
          }

          if (rowIdx === fundDataRows.length - 1) {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
                bottom: {
                  style: 'medium',
                },
              };
            } else if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
                bottom: {
                  style: 'medium',
                },
              };
            } else {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
              };
            }
          } else {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
              };
            }

            if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
              };
            }
          }
        });
      });

      worksheet.addRow([]);
      ExcelUtils.addFooter(worksheet, 'A', 'C');

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `របាយការណ៍មូលធន - ${new Date().getTime()}.xlsx`);
      });
    } catch (error) {
      console.error(error);
    }
  }

  generateFinancialPositionStatementReport() {
    try {
      const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('របាយការណ៍ស្ថានភាពហិរញ្ញវត្ថុ');
      worksheet.pageSetup = {
        orientation: 'portrait',
        paperSize: 9,
      };
      worksheet.getColumn('A').width = 70;
      worksheet.getColumn('B').width = 8;
      worksheet.getColumn('C').width = 25;
      worksheet.getColumn('D').width = 25;
      worksheet.getColumn('E').width = 25;

      ExcelUtils.addNationalMotto(worksheet, 'A', 1);
      // logo
      ExcelUtils.addLogo(workbook, worksheet);
      ExcelUtils.addNSSFInfo(worksheet, 'A', 4);
      const titleCell = worksheet.getCell(`A${worksheet.rowCount + 3}`);
      titleCell.value =
        'របាយការណ៍ស្ថានភាពហិរញ្ញវត្ថុ (Statement of Financial Position)';
      titleCell.style = {
        font: {
          name: 'Khmer OS Muol Light',
          size: 12,
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '8EA9DB' },
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
        border: {
          bottom: {
            style: 'medium',
          },
          top: {
            style: 'medium',
          },
          left: {
            style: 'medium',
          },
          right: {
            style: 'medium',
          },
        },
      };
      worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
      const subTitleRows = worksheet.getRows(worksheet.rowCount + 1, 2);
      subTitleRows.forEach((row, rowIdx) => {
        if (rowIdx === 0) {
          row.values = ['', 'កូដ', 'សមតុល្យសរុប', 'សមតុល្យសរុប', 'សមតុល្យសរុប'];
        } else {
          row.values = ['', '', '២០២៤', '២០២៣', '២០២២'];
        }
        for (let cellIdx = 1; cellIdx <= 5; cellIdx++) {
          const cell = row.getCell(cellIdx);
          cell.style = {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D9E1F2' },
            },
            font: {
              name: 'Khmer OS Battambang',
              size: 12,
            },
            alignment: {
              horizontal: 'center',
              vertical: 'middle',
            },
          };

          switch (rowIdx) {
            case 0:
              if (cellIdx === 1) {
                cell.border = {
                  left: {
                    style: 'medium',
                  },
                  top: {
                    style: 'medium',
                  },
                };
              } else if (cellIdx === 5) {
                cell.border = {
                  right: {
                    style: 'medium',
                  },
                  top: {
                    style: 'medium',
                  },
                };
              } else {
                cell.border = {
                  top: {
                    style: 'medium',
                  },
                };
              }
              break;
            case subTitleRows.length - 1:
              if (cellIdx === 1) {
                cell.border = {
                  left: {
                    style: 'medium',
                  },
                  bottom: {
                    style: 'medium',
                  },
                };
              } else if (cellIdx === 5) {
                cell.border = {
                  right: {
                    style: 'medium',
                  },
                  bottom: {
                    style: 'medium',
                  },
                };
              } else {
                cell.border = {
                  bottom: {
                    style: 'medium',
                  },
                };
              }
              break;
            default:
              break;
          }
        }
      });

      worksheet.mergeCells(`B${worksheet.rowCount - 1}:B${worksheet.rowCount}`);
      worksheet.getCell(`B${worksheet.rowCount - 1}`).border = {
        bottom: {
          style: 'medium',
        },
      };

      worksheet.addRow(['ទ្រព្យសរុប (Total Asset)', '', '', '', '']);
      worksheet.addRow(['ទ្រព្យសកម្ម', '', '', '', '']);
      worksheet.addRow(['សាច់ប្រាក់ក្នុងធនាគារ', '', '', '', '']);
      worksheet.addRow(['បញ្ញើមានកាលកំណត់រយៈពេលខ្លី', '', '', '', '']);
      worksheet.addRow(['មូលបត្ររយៈពេលខ្លី', '', '', '', '']);
      worksheet.addRow(['មូលបត្រក្រៅប្រទេសរយៈពេលខ្លី', '', '', '', '']);
      worksheet.addRow(['ការប្រាក់បង្គរមិនទាន់សម្រេច', '', '', '', '']);
      worksheet.addRow(['ទ្រព្យអសកម្ម', '', '', '', '']);
      worksheet.addRow(['បញ្ញើមានកាលកំណត់រយៈពេលវែង', '', '', '', '']);
      worksheet.addRow([
        'សញ្ញាបណ្ណសាជីវកម្មរយៈពេលវែង (សញ្ញាបណ្ណមានបុគ្គលធានាCIA)',
        '',
        '',
        '',
        '',
      ]);
      worksheet.addRow(['មូលបត្រក្រៅប្រទេសរយៈពេលវែង', '', '', '', '']);
      worksheet.addRow(['មូលបត្ររដ្ឋ', '', '', '', '']);
      worksheet.addRow(['មូលបត្រកម្មសិទ្ធិ', '', '', '', '']);
      worksheet.addRow(['អចលនទ្រព្យ', '', '', '', '']);
      worksheet.addRow(['គម្រោងភាពជាដៃគូរដ្ឋ និងឯកជន', '', '', '', '']);
      worksheet.addRow(['បំណុលសរុប និងមូលធនសរុប', '', '', '', '']);
      worksheet.addRow(['បំណុលសរុប', '', '', '', '']);
      worksheet.addRow(['មូលធនសរុប', '', '', '', '']);
      worksheet.addRow(['ដើមទុនវិនិយោគ', '', '', '', '']);
      worksheet.addRow(['ចំណូល', '', '', '', '']);
      worksheet.addRow(['ចំណូលការប្រាក់', '', '', '', '']);
      worksheet.addRow(['ចំណូលពីការប្រាក់បង្គរមិនទាន់សម្រេច', '', '', '', '']);
      worksheet.addRow(['ចំណូលភាគលាភ', '', '', '', '']);
      worksheet.addRow(['ចំណូលទុនបន្ថែម', '', '', '', '']);
      worksheet.addRow(['ចំណូលពីប្រតិបត្តិការវិនិយោគផ្សេងៗ', '', '', '', '']);
      worksheet.addRow(['ចំណាយ', '', '', '', '']);
      worksheet.addRow(['ចំណាយកំរៃជើងសារមូលបត្រ', '', '', '', '']);
      worksheet.addRow(['ចំណាយកំរៃជើងសារ (EFM)', '', '', '', '']);
      worksheet.addRow(['ចំណាយពន្ធ', '', '', '', '']);
      worksheet.addRow(['ភាគលាភ', '', '', '', '']);
      worksheet.addRow(['ចំណេញ/ខាតលើអត្រាប្តូរប្រាក់', '', '', '', '']);

      const dataRows = worksheet.getRows(worksheet.rowCount - 30, 31);
      dataRows.forEach((row, rowIdx) => {
        row.eachCell((cell, colNumber) => {
          cell.font = {
            name: 'Khmer OS Battambang',
            size: 12,
          };
          if (colNumber !== 1) {
            cell.alignment = {
              horizontal: 'right',
              vertical: 'middle',
            };
          } else {
            cell.alignment = {
              horizontal: 'left',
              vertical: 'middle',
            };
          }

          if (rowIdx === dataRows.length - 1) {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
                bottom: {
                  style: 'medium',
                },
              };
            } else if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
                bottom: {
                  style: 'medium',
                },
              };
            } else {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
              };
            }
          } else {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
              };
            }

            if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
              };
            }
          }
        });
      });
      worksheet.addRow([]);
      ExcelUtils.addFooter(worksheet, 'A', 'C');
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(
          blob,
          `របាយការណ៍ស្ថានភាពហិរញ្ញវត្ថុ - ${new Date().getTime()}.xlsx`
        );
      });
    } catch (error) {
      console.error(error);
    }
  }

  generateFinancialHighlightReport() {
    try {
      const workbook: ExcelJS.Workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('របាយការណ៍សង្ខេបហិរញ្ញវត្ថុ');
      worksheet.pageSetup = {
        orientation: 'portrait',
        paperSize: 9,
      };
      worksheet.getColumn('A').width = 45;
      worksheet.getColumn('B').width = 8;
      worksheet.getColumn('C').width = 25;
      worksheet.getColumn('D').width = 25;
      worksheet.getColumn('E').width = 25;
      ExcelUtils.addNationalMotto(worksheet, 'A', 1);
      // logo
      ExcelUtils.addLogo(workbook, worksheet);
      ExcelUtils.addNSSFInfo(worksheet, 'A', 4);
      const titleCell = worksheet.getCell(`A${worksheet.rowCount + 3}`);
      titleCell.value = 'របាយការណ៍សង្ខេបហិរញ្ញវត្ថ (Financial Highlight)';
      titleCell.style = {
        font: {
          name: 'Khmer OS Muol Light',
          size: 12,
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '8EA9DB' },
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
        border: {
          bottom: {
            style: 'medium',
          },
          top: {
            style: 'medium',
          },
          left: {
            style: 'medium',
          },
          right: {
            style: 'medium',
          },
        },
      };
      worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
      const subTitleRows = worksheet.getRows(worksheet.rowCount + 1, 2);
      subTitleRows.forEach((row, rowIdx) => {
        if (rowIdx === 0) {
          row.values = ['', 'កូដ', 'សមតុល្យសរុប', 'សមតុល្យសរុប', 'សមតុល្យសរុប'];
        } else {
          row.values = ['', '', '២០២៤', '២០២៣', '២០២២'];
        }
        for (let cellIdx = 1; cellIdx <= 5; cellIdx++) {
          const cell = row.getCell(cellIdx);
          cell.style = {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D9E1F2' },
            },
            font: {
              name: 'Khmer OS Battambang',
              size: 12,
            },
            alignment: {
              horizontal: 'center',
              vertical: 'middle',
            },
          };

          switch (rowIdx) {
            case 0:
              if (cellIdx === 1) {
                cell.border = {
                  left: {
                    style: 'medium',
                  },
                  top: {
                    style: 'medium',
                  },
                };
              } else if (cellIdx === 5) {
                cell.border = {
                  right: {
                    style: 'medium',
                  },
                  top: {
                    style: 'medium',
                  },
                };
              } else {
                cell.border = {
                  top: {
                    style: 'medium',
                  },
                };
              }
              break;
            case subTitleRows.length - 1:
              if (cellIdx === 1) {
                cell.border = {
                  left: {
                    style: 'medium',
                  },
                  bottom: {
                    style: 'medium',
                  },
                };
              } else if (cellIdx === 5) {
                cell.border = {
                  right: {
                    style: 'medium',
                  },
                  bottom: {
                    style: 'medium',
                  },
                };
              } else {
                cell.border = {
                  bottom: {
                    style: 'medium',
                  },
                };
              }
              break;
            default:
              break;
          }
        }
      });

      worksheet.mergeCells(`B${worksheet.rowCount - 1}:B${worksheet.rowCount}`);
      worksheet.getCell(`B${worksheet.rowCount - 1}`).border = {
        bottom: {
          style: 'medium',
        },
      };

      worksheet.addRow(['សាច់ប្រាក់ក្នុងធនាគារ', '', 10000000, 23000000, '']);
      worksheet.addRow([
        'មូលបត្ររយៈពេលវែង (បុគ្គលធានាCIA)',
        '',
        10000000,
        23000000,
        1800000,
      ]);
      worksheet.addRow([
        'ការប្រាក់បង្គរមិនទាន់សម្រេច',
        '',
        13000000,
        25000000,
        1600000,
      ]);
      worksheet.addRow([
        'ចំណូលការប្រាក់បន្ទាប់ពីបង់ពន្ធ',
        '',
        11000000,
        22000000,
        '',
      ]);

      worksheet.addRow([
        'ចំណូលពីបង្គរការប្រាក់មិនទាន់សម្រេច',
        '',
        11000000,
        22000000,
        33500000,
      ]);

      worksheet.addRow([
        'មូលបត្ររយៈពេលវែង (បុគ្គលធានាCIA)',
        '',
        40000000000,
        40000000000,
        40000000000,
      ]);

      worksheet.addRow([
        'ដើមទុនវិនិយោគសរុប',
        '',
        45000000000,
        45000000000,
        45000000000,
      ]);

      const dataRows = worksheet.getRows(worksheet.rowCount - 6, 7);
      dataRows.forEach((row, rowIdx) => {
        row.eachCell((cell, colNumber) => {
          cell.font = {
            name: 'Khmer OS Battambang',
            size: 12,
          };
          if (colNumber !== 1) {
            cell.alignment = {
              horizontal: 'right',
              vertical: 'middle',
            };
          } else {
            cell.alignment = {
              horizontal: 'left',
              vertical: 'middle',
            };
          }

          if (rowIdx === dataRows.length - 1) {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
                bottom: {
                  style: 'medium',
                },
              };
            } else if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
                bottom: {
                  style: 'medium',
                },
              };
            } else {
              cell.border = {
                bottom: {
                  style: 'medium',
                },
              };
            }
          } else {
            if (colNumber === 1) {
              cell.border = {
                left: {
                  style: 'medium',
                },
              };
            }

            if (colNumber === 5) {
              cell.border = {
                right: {
                  style: 'medium',
                },
              };
            }
          }
        });
      });

      worksheet.addRow([]);
      worksheet.addRow(['អនុបាតប្រៀបធៀប ២០២៤/២០២៣']);
      worksheet.addRow(['ទ្រព្យសកម្មកើនឡើង', '100%']);
      worksheet.addRow(['ចំណូលប្រចាំឆ្នាំកើនឡើង', '100%']);
      worksheet.addRow(['ដើមទុនវិនិយោគសរុបកើនឡើង', '10%']);
      worksheet.addRow(['ផលពីការវិនិយោគប្រចាំឆ្នាំ (ROI)', '11%']);

      worksheet.mergeCells(
        `A${worksheet.rowCount - 4}:C${worksheet.rowCount - 4}`
      );
      worksheet.mergeCells(
        `B${worksheet.rowCount - 3}:C${worksheet.rowCount - 3}`
      );
      worksheet.mergeCells(
        `B${worksheet.rowCount - 2}:C${worksheet.rowCount - 2}`
      );
      worksheet.mergeCells(
        `B${worksheet.rowCount - 1}:C${worksheet.rowCount - 1}`
      );
      worksheet.mergeCells(`B${worksheet.rowCount}:C${worksheet.rowCount}`);

      const compareDataRows = worksheet.getRows(worksheet.rowCount - 4, 5);
      compareDataRows.forEach((row, rowIdx) => {
        row.eachCell((cell, colNumber) => {
          cell.font = {
            name: 'Khmer OS Battambang',
            size: 12,
          };
          cell.border = {
            bottom: {
              style: 'thin',
            },
            top: {
              style: 'thin',
            },
            right: {
              style: 'thin',
            },
            left: {
              style: 'thin',
            },
          };
          if (rowIdx !== 0) {
            if (colNumber === 1) {
              cell.alignment = {
                horizontal: 'left',
                vertical: 'middle',
              };
            } else {
              cell.alignment = {
                horizontal: 'right',
                vertical: 'middle',
              };
            }
          } else {
            cell.alignment = {
              horizontal: 'center',
              vertical: 'middle',
            };
            cell.font = {
              name: 'Khmer OS Battambang',
              size: 12,
              bold: true,
            };
          }
        });
      });

      worksheet.addRow([]);
      ExcelUtils.addFooter(worksheet, 'A', 'C');

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(
          blob,
          `របាយការណ៍សង្ខេបហិរញ្ញវត្ថុ - ${new Date().getTime()}.xlsx`
        );
      });
    } catch (error) {
      console.error(error);
    }
  }
}
