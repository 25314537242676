import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { logo } from '../shared/constants/base64';

@Injectable({
  providedIn: 'root',
})
export class ExcelUtils {
  public static addLogo(
    workbook: ExcelJS.Workbook,
    worksheet: ExcelJS.Worksheet
  ) {
    const logoImage = workbook.addImage({
      extension: 'png',
      base64: logo,
    });
    worksheet.addImage(logoImage, {
      tl: { col: 0.9, row: 0.8 },
      ext: { width: 70, height: 70 },
    });
  }

  public static addNationalMotto(
    worksheet: ExcelJS.Worksheet,
    startingColumn: string,
    startingRow: number
  ) {
    worksheet.getCell(`${startingColumn}${startingRow}`).value =
      'ព្រះរាជាណាចក្រកម្ពុជា';
    worksheet.getCell(`${startingColumn}${startingRow}`).style = {
      font: {
        name: 'Khmer OS Muol Light',
        size: 13,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'center',
      },
    };

    worksheet.mergeCells(`${startingColumn}${startingRow}:E${startingRow}`);
    worksheet.getCell(`${startingColumn}${worksheet.rowCount + 1}`).value =
      'ជាតិ សាសនា ព្រះមហាក្សត្រ';

    worksheet.getCell(`${startingColumn}${worksheet.rowCount}`).style = {
      font: {
        name: 'Khmer OS Muol Light',
        size: 13,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'center',
      },
    };
    worksheet.mergeCells(
      `${startingColumn}${worksheet.rowCount}:E${worksheet.rowCount}`
    );
  }

  public static addNSSFInfo(
    worksheet: ExcelJS.Worksheet,
    startingColumn: string,
    startingRow: number
  ) {
    const nssfCell = worksheet.getCell(`${startingColumn}${startingRow}`);
    nssfCell.value = 'បេឡាជាតិរបបសន្ដិសុខសង្គម';
    nssfCell.style = {
      font: {
        name: 'Khmer OS Muol Light',
        size: 12,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'left',
      },
    };

    const depCell = worksheet.getCell(
      `${startingColumn}${worksheet.rowCount + 1}}`
    );
    depCell.value = 'នាយកដ្ឋានគ្រប់គ្រងការវិនិយោគមូលនិធិសន្តិសុខសង្គម';
    depCell.style = {
      font: {
        name: 'Khmer OS Battambang',
        size: 12,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'left',
      },
    };
    const officeCell = worksheet.getCell(
      `${startingColumn}${worksheet.rowCount + 1}}`
    );
    officeCell.value = 'ផ្នែកបញ្ជិកាគណនេយ្យ​ និងទូទាត់';
    officeCell.style = {
      font: {
        name: 'Khmer OS Battambang',
        size: 12,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'left',
      },
    };
  }

  public static addFooter(
    worksheet: ExcelJS.Worksheet,
    leftStartColumn: string,
    rightStartingColumn: string
  ) {
    worksheet.addRow([
      '',
      '',
      'ថ្ងៃ                             ខែ              ឆ្នាំរោង ឆស័ក ព.ស.២៥៦៨',
    ]);
    worksheet.addRow([
      '',
      '',
      'រាជធានីភ្នំពេញ ថ្ងៃទី             ខែ                 ឆ្នាំ២០២៤',
    ]);
    worksheet.addRow(['បានឃើញ និងឯកភាព', '', 'អ្នករៀបចំតារាង']);
    worksheet.addRow([
      'រាជធានីភ្នំពេញ ថ្ងៃទី             ខែ                 ឆ្នាំ២០២៤',
    ]);
    worksheet.addRow(['អនុប្រធាននាយកដ្ឋាន']);
    worksheet.mergeCells(
      `${rightStartingColumn}${worksheet.rowCount - 4}:E${
        worksheet.rowCount - 4
      }`
    );
    worksheet.mergeCells(
      `${rightStartingColumn}${worksheet.rowCount - 3}:E${
        worksheet.rowCount - 3
      }`
    );
    worksheet.mergeCells(
      `${rightStartingColumn}${worksheet.rowCount - 2}:E${
        worksheet.rowCount - 2
      }`
    );
    worksheet.getCell(`${rightStartingColumn}${worksheet.rowCount - 4}`).style =
      {
        font: {
          name: 'Khmer OS Battambang',
          size: 12,
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
      };
    worksheet.getCell(`${rightStartingColumn}${worksheet.rowCount - 3}`).style =
      {
        font: {
          name: 'Khmer OS Battambang',
          size: 12,
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
      };
    worksheet.getCell(`${rightStartingColumn}${worksheet.rowCount - 2}`).style =
      {
        font: {
          name: 'Khmer OS Battambang',
          size: 12,
          bold: true,
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
      };

    worksheet.mergeCells(
      `${leftStartColumn}${worksheet.rowCount - 2}:B${worksheet.rowCount - 2}`
    );
    worksheet.mergeCells(
      `${leftStartColumn}${worksheet.rowCount - 1}:B${worksheet.rowCount - 1}`
    );
    worksheet.mergeCells(
      `${leftStartColumn}${worksheet.rowCount}:B${worksheet.rowCount}`
    );

    worksheet.getCell(`${leftStartColumn}${worksheet.rowCount - 2}`).style = {
      font: {
        name: 'Khmer OS Battambang',
        size: 12,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'center',
      },
    };
    worksheet.getCell(`${leftStartColumn}${worksheet.rowCount - 1}`).style = {
      font: {
        name: 'Khmer OS Battambang',
        size: 12,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'center',
      },
    };

    worksheet.getCell(`${leftStartColumn}${worksheet.rowCount}`).style = {
      font: {
        name: 'Khmer OS Muol Light',
        size: 12,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'center',
      },
    };
  }
}
